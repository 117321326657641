export const BoundingBoxActionTypes = {
  SET_ACTIVE_DOCUMENT_INDEX: 'SET_ACTIVE_DOCUMENT_INDEX',
  SET_BBOX: 'SET_BBOX',
  RESET_BBOX: 'RESET_BBOX',
  SET_ALL_BBOX: 'SET_ALL_BBOX',
  RESET_ALL_BBOX: 'RESET_ALL_BBOX',
  SET_SHOW_ALL_BBOX: 'SET_SHOW_ALL_BBOX',
  SET_ANNOTATIONS: 'SET_ANNOTATIONS',
  RESET_ANNOTATIONS: 'RESET_ANNOTATIONS',
  RESET_BOUNDING_BOX: 'RESET_BOUNDING_BOX',
  SET_ACTIVE_BBOX_TYPE: 'SET_ACTIVE_BBOX_TYPE',
  SET_TABLE_CREATOR: 'SET_TABLE_CREATOR',
  RESET_TABLE_CREATOR: 'RESET_TABLE_CREATOR',
  SET_RESIZABLE_BBOXES: 'SET_RESIZABLE_BBOXES',
  SET_MODIFIED_BBOXES: 'SET_MODIFIED_BBOXES',
  SET_OCR_DATA: 'SET_OCR_DATA',
};
