import React from 'react';
import {
  string, func, bool,
} from 'prop-types';

import './TextButton.css';

function TextButton(props) {
  const {
    hitlShouldEnable,
    onClick,
    text,
    isIcon,
    icon,
    defaultClass,
  } = props;
  return (
    <div>
      <button
        className={`${
          hitlShouldEnable ? 'enable-add-row' : 'disable-add-row'
        } row-add-button`}
        type="button"
        disabled={!hitlShouldEnable}
        onClick={hitlShouldEnable && onClick}
      >
        {isIcon && <img src={icon} alt="plus-icon" />}

        <span className={`add-row-text ${defaultClass}`}>{text}</span>
      </button>
    </div>
  );
}

TextButton.propTypes = {
  hitlShouldEnable: bool,
  onClick: func.isRequired,
  text: string.isRequired,
  isIcon: bool,
  icon: string,
  defaultClass: string,
};

TextButton.defaultProps = {
  hitlShouldEnable: true,
  isIcon: false,
  icon: '',
  defaultClass: 'h5Regular',
};

export default TextButton;
